import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/notice.css"

const notice = () => (
  <Layout>
    <SEO title="Notice" />
    <div className="mainContent">
      <div className="noticePage">
        <div className="content">
          <div className="noticeHeader">공고 / 공지</div>
          <div className="noticeBody">
            <div className="titleArea">
              <div className="title">외부감사인 변경 선임 공고</div>
              <div className="date">2022-11-03</div>
            </div>
            <div className="textArea">
              <div className="contentsArea">
                <div className="textTitle">외부감사인 변경 선임 공고</div>
                <div className="textDesc">
                  <p>
                    주식회사 등의 외부감사에 관한 법률 제12조 1항 및 동법 시행령 제18조 1항에 의거, 당사의 외부감사인 변경 선임
                    사실을 다음과 같이 공고합니다.
                  </p>
                  <br />
                  <p>
                    - 외부감사인 변경
                    <table>
                      <tr>
                        <th>변경 전</th>
                        <th>변경 후</th>
                      </tr>
                      <tr>
                        <td>삼도회계법인</td>
                        <td>삼일회계법인</td>
                      </tr>
                    </table>
                    <br />
                    - 감사대상기간 : 2022년 1월 1일 ~ 2022년 12월 31일
                    <br />
                    - 변경사유: 주식회사 등의 외부감사에 관한 법률 제11조 제1항 제12호 및 동법 시행령 제14 조 제6항 제1호에 따른
                    증권선물위원회에 의한 감사인 지정
                    <br />
                  </p>
                  <br />
                  <br />
                  <p className="footer">
                    2022년 11월 03일
                    <br />
                    주식회사 스테이지파이브 대표이사 서 상 원<br />
                    서울특별시 송파구 올림픽로35 123, 6층(신천동, 향군타워)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default notice
